<template>
  <div>
    <Bj :bjData="bjData" />
    <div class="myTitle_box" style="padding-top: 78px">
      <div class="myTitle">品牌介绍</div>
      <div class="myTitle_behind">INTRODUCE</div>
    </div>
    <div class="introduce">
      <div style="width: 513px; padding-top: 45px">
        <div>
          聚趣教育成立于2018年，是一家为全球用户提供一站式在线语言学习的数字化平台。公司围绕教育，科技，服务三大核心内核。利用“互联网+”结合大数据、人工智能和语言科学探索未来教育新模式。
        </div>
        <div style="padding: 46px 0">
          “科技使学习更有趣”是聚趣教育的一直秉承的理念。公司从创立开始，自主开发了德语、法语、韩语、西语、日语等优质课程产品及工具型APP产品，在线注册会员用户超400万人，学生遍布全球40个国家和地区。
        </div>
        <div>
          95%老师拥有留学经验和硕士学位，300+世界名校毕业老师，海外名师均来自全球TOP150排名院校。依靠强大的师资团队，聚趣研发推出20+类语言教学新模式，包含日韩德法西等多种小语种的培训教学，涵盖高考、考研、留学、工作等不同方向的学习需求。
        </div>
      </div>
      <div class="introduce_img_box">
        <div class="introduce_square" v-for="item in 2" :key="item"></div>
        <img src="@/assets/pc/news/company3 (2).jpg" alt="" srcset="" />
        <!-- <carousel
					class="introduce_img"
					style="margin:0"
					ref="carousel"
					:carouselData="carouselData"
				></carousel> -->
      </div>
    </div>
    <div class="video_box flexVerticalCenter">
      <img src="@/assets/pc/news/video_bg.png" alt="" mode="widthFix" />
      <div class="video">
        <video
          style="width: 100%; height: 100%"
          id="mv"
          src="https://qn.juquedu.com/71bce58eafa346f8b9a0c015a0ad4783"
          :poster="poster"
          x5-playsinline="true"
          webkit-playsinline="true"
          playsinline="true"
          controls
        ></video>
      </div>
    </div>
    <div class="myTitle_box" style="padding-top: 100px">
      <div class="myTitle">聚趣产品</div>
      <div class="myTitle_behind">PRODUCT</div>
    </div>
    <div class="miniTitle">
      400W用户选择 全网10000+种草推荐 同语言类目App Store下载量第一
    </div>
    <div class="classification_out flexVerticalCenter">
      <div
        class="classification flexVerticalCenter"
        v-for="(item, i) in classificationList"
        :key="i"
        :style="{
          fontWeight: classification == item.text ? 'bold' : '400',
        }"
        @click="selectClassification(item)"
      >
        {{ item.text }}
        <div v-if="classification == item.text" class="classification_active"></div>
      </div>
    </div>
    <div class="classification_img_box">
      <img class="classification_img" :src="classificationImg" alt="" />
      <img
        class="classification_icon"
        src="@/assets/pc/news/left.png"
        alt=""
        mode="widthFix"
      />
      <img
        class="classification_icon"
        src="@/assets/pc/news/right.png"
        alt=""
        mode="widthFix"
      />
    </div>
  </div>
</template>
<script>
import Bj from "@/components/pc/bj";
// import carousel from '@/components/pc/carousel'
export default {
  components: {
    Bj,
    // carousel,
  },
  data() {
    return {
      bjData: {
        imgUrl: "https://qn.juquedu.com/newsbj-20240627154108.png",
        title: "聚趣教育",
        minTitle: "为互联网学习用户提供真正优质的数字化学习产品",
      },
      //poster: require('@/assets/pc/home/poster.jpg'),
      poster: "https://qn.juquedu.com/f1066eb240c140d9b158a77fe1ac427b",
      // banner数据
      carouselData: [
        require("@/assets/pc/news/company1.png"),
        require("@/assets/pc/news/company2.jpg"),
        require("@/assets/pc/news/company3.jpg"),
        require("@/assets/pc/news/company4.jpg"),
        require("@/assets/pc/news/company5.png"),
      ],
      classificationList: [
        { text: "日语GO", img: require("@/assets/pc/news/ri.png") },
        { text: "德语GO", img: require("@/assets/pc/news/de.png") },
        { text: "法语GO", img: require("@/assets/pc/news/fa.png") },
        { text: "韩语GO", img: require("@/assets/pc/news/han.png") },
        { text: "西语GO", img: require("@/assets/pc/news/xi.png") },
        { text: "雅思GO", img: require("@/assets/pc/news/yasi.png") },
        {
          text: "德语背单词",
          img: require("@/assets/pc/news/debei.png"),
        },
        {
          text: "LingoMaster",
          img: require("@/assets/pc/news/lingo.png"),
        },
      ],
      classification: "日语GO",
      classificationImg: require("@/assets/pc/news/ri.png"),
    };
  },
  methods: {
    // 选择语言
    selectClassification(value) {
      this.classification = value.text;
      this.classificationImg = value.img;
    },
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  width: 1200px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 32px;
  padding: 36px 0 80px;
  display: flex;
  .introduce_img_box {
    width: 634px;
    height: 541px;
    margin-left: 57px;
    position: relative;
    padding: 116px 35px 0;
    box-sizing: border-box;
    img {
      width: 599px;
      // height: 496px;
      position: relative;
      z-index: 2;
    }
    .introduce_img {
      width: 496px;
      height: 496px;
      position: relative;
      z-index: 2;
    }
    .introduce_square {
      position: absolute;
      z-index: 1;
    }
    .introduce_square:first-child {
      width: 234px;
      height: 234px;
      background: #175bf8;
      top: 66px;
      right: 0;
    }
    .introduce_square:nth-child(2) {
      width: 79px;
      height: 79px;
      background: #78c757;
      bottom: 59px;
      left: 0;
    }
  }
}
.video_box {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .video {
    width: calc(100% / 1.95);
    height: calc(100% / 1.41);
    position: absolute;
    top: calc(100% / 9);
    left: calc(100% / 4.13);
    background: #000000;
  }
}
.classification_out {
  width: 100%;
  padding: 64px 0 72px;
  .classification {
    height: 53px;
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    margin-right: 49px;
    cursor: pointer;
    position: relative;
  }
  .classification:last-child {
    margin-right: 0;
  }
  .classification_active {
    width: 49px;
    margin: auto;
    content: "";
    position: absolute;
    bottom: -8px;
    display: block;
    height: 3px;
    text-align: center;
    background: #333333;
    animation: fadeInLeft 0.3s ease 0.2s 1 both;
  }
}
.classification_img_box {
  width: 100%;
  height: 672px;
  position: relative;
  .classification_img {
    width: 1200px;
    height: 555px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .classification_icon {
    position: absolute;
    z-index: 1;
  }
  .classification_icon:nth-child(2) {
    width: 108px;
    height: 325px;
    bottom: 80px;
    left: 0;
  }
  .classification_icon:nth-child(3) {
    width: 226px;
    height: 436px;
    top: 0;
    right: 0;
  }
}
</style>
