<template>
	<div class="bjBox">
		<img class="bj" :src="bjData.imgUrl" alt="" />
		<div class="titleBox">
			<div>{{ bjData.title }}</div>
			<div>{{ bjData.minTitle }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		bjData: Object,
	},
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.bjBox {
	position: relative;
}
.bj {
	min-width: 1200px;
	width: 100%;
	display: block;
}
.titleBox {
	color: #fff;
	width: 1200px;
	height: 200px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	text-align: center;
	letter-spacing: 10px;

	div:nth-child(1) {
		font-size: 72px;
		font-weight: 800;
	}
	div:nth-child(2) {
		margin-top: 18px;
		font-size: 30px;
	}
}
</style>
